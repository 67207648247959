<template>
  <div class="page1 treeWraper">
    <!--主体内容-->
    <div class="content">
      <div class="content-wrapper">
        <div class="all">
          <!-- <el-button @click="setAuthority(true)">全部选择</el-button>
          <el-button @click="setAuthority(false)">全部取消</el-button>-->
          <span class="titleLeft" name>权限分配</span>
          <el-checkbox v-model="checkAll" @change="handleCheckAllChange" :disabled="!isBIANJI"
            >全选</el-checkbox
          >
        </div>
        <el-tree
          ref="tree"
          :data="sourceData"
          @check="nodeClickHandle"
          :props="treeProp"
          show-checkbox
          accordion
          node-key="code"
          :filter-node-method="returnFilter"
          :expand-on-click-node="false"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <span class="setUp_edit" v-if="data.parentCode != 0 && noTrue" @click="changeUp"
              >编辑样式</span
            >
          </span>
        </el-tree>
        <!-- <tree-con ref='treeComponent' :sourceData='sourceData' :selected='selected'></tree-con> -->
        <div style="text-align: center" v-if="isBIANJI">
          <el-divider></el-divider>
          <el-button type="primary" @click="save" style="width: 88px">{{ $t('button.preservation') }}</el-button>
          <el-button @click="isBIANJI = false" style="width: 88px">取消</el-button>
        </div>
        <div style="text-align: center" v-if="!isBIANJI">
          <el-divider></el-divider>
          <el-button type="primary" @click="isBIANJI = true" style="width: 88px">{{ $t('button.edit') }}</el-button>
        </div>
      </div>
      <el-drawer size="30%" title="编辑样式" :visible.sync="dialogVisible" :direction="direction">
        <el-form
          label-position="right"
          label-width="100px"
          :model="formInline"
          :rules="rules"
          :ref="'form'"
        >
          <el-form-item label="应用名称：" prop="monitorEquipmentName">
            <el-input
              :maxlength="20"
              v-model.trim="formInline.monitorEquipmentName"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="英文名称：" prop="monitorEquipmentName">
            <el-input
              :maxlength="20"
              v-model.trim="formInline.monitorEquipmentName"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="显示位置：" prop="operationId">
            <el-select v-model.trim="formInline.operationId" filterable size="15">
              <el-option value="1">1</el-option>
              <el-option value="2">2</el-option>
              <el-option value="3">3</el-option>
              <el-option value="4">4</el-option>
              <el-option value="5">5</el-option>
              <el-option value="6">6</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="应用icon：" prop="logoId">
            <el-upload
              :disabled="!isBIANJI"
              class="avatar-uploader"
              :headers="uploadHeader"
              action="/acb/2.0/deptProject/uploadLogo"
              style="display: inline-block"
              name="image"
              :data="{ deptId: {} }"
              :show-file-list="false"
              :on-success="
                (response, file, fileList) => handleAvatarSuccess(response, file, fileList, 3)
              "
              :before-upload="(file) => beforeAvatarUpload(file, 'logoId')"
            >
              <img v-if="logoUrl" :src="logoUrl" class="avatar-small" />
              <i v-else class="el-icon-plus avatar-uploader-icon-small"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="">
            <p class="img_illustrate">只能上传(jpg,jpeg,bmp,png)格式，尺寸120*160且大小不超过5M.</p>
          </el-form-item>
        </el-form>
        <div class="button_margin">
          <el-button class="el_button" type="primary" @click="submitSet">{{ $t('button.preservation') }}</el-button>
          <el-button class="el_button_off" @click="dialogVisible = false">取消</el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
export default {
  name: "hello",
  props: {
    deptId: {
      default: "",
    },
  },
  data() {
    return {
      noTrue: false,
      direction: "rtl",
      loginLogoUrl: "",
      dialogVisible: false,
      checkAll: false,
      uploadHeader: {
        "Session-Id": sessionStorage.token,
      },
      checkArr: [],
      treeList: [],
      sourceData: [],
      queryArr: [],
      treeProp: {
        children: "children",
        label: "desc",
      },
      selected: [],
      resourceList: [],
      formInline: {
        resourceIds: "",
        elecResourceIds: "",
      },
      isBIANJI: false,
      deptManagerResourceId: "",
      rules: {},
    };
  },
  watch: {
    isBIANJI(oldVal, newVal) {
      this.setIsDeabled(newVal);
      this.getResource();
    },
    deep: true,
    immediate: true,
  },
  methods: {
    setIsDeabled(newVal) {
      let data = this.sourceData;
      data.forEach((v) => {
        v.disabled = newVal;
        if (v.children && v.children.length) {
          v.children.forEach((e) => {
            e.disabled = newVal;
            if (e.children && e.children.length) {
              e.children.forEach((ee) => {
                ee.disabled = newVal;
                if (ee.children && ee.children.length) {
                  ee.children.forEach((el) => {
                    el.disabled = newVal;
                  });
                }
              });
            }
          });
        }
      });
      return data;
    },
    returnFilter(value, data, node) {
      return data.parentCode === "0";
    },
    handleCheckAllChange() {
      let arr = [];
      if (this.checkAll) {
        this.sourceData.forEach((v) => {
          v.children.forEach((element) => {
            arr.push(element.code);
          });
        });
      } else {
        arr = this.$refs.tree.getCheckedKeys();
      }
      arr.forEach((v) => {
        this.$nextTick(() => {
          this.$refs.tree.setChecked(v, this.checkAll, true);
        });
      });
    },
    nodeClickHandle(data, arg) {
      console.log(data, "kkkk");
      if (data.function && data.function == "query") {
        let curNode = this.$refs.tree.getNode(data.code);

        if (!curNode.checked) {
          let parentNode = curNode.parent;
          this.$refs.tree.setChecked(parentNode.data, false, true);
        }
        let parentNodeT = curNode.parent.parent;
        let childNodesT = parentNodeT.childNodes;
        let queryNodeT = childNodesT.find((v) => {
          return v.data.function == "query";
        });
        if (!queryNodeT.checked) {
          this.$refs.tree.setChecked(queryNodeT.data, true, false);
        }
      } else if (data.function && data.function != "query") {
        let curNode = this.$refs.tree.getNode(data.code);
        if (curNode.checked) {
          let parentNode = curNode.parent;
          let childNodes = parentNode.childNodes;
          let queryNode = childNodes.find((v) => {
            return v.data.function == "query";
          });
          if (!queryNode.checked) {
            this.$refs.tree.setChecked(queryNode.data, true, false);
          }
          let parentNodeT = curNode.parent.parent;
          let childNodesT = parentNodeT.childNodes;
          let queryNodeT = childNodesT.find((v) => {
            return v.data.function == "query";
          });
          if (!queryNodeT.checked) {
            this.$refs.tree.setChecked(queryNodeT.data, true, false);
          }
        }
      }
      let allCheckList = this.$refs.tree.getCheckedNodes(true);
      this.checkAll = this.checkArr.length === allCheckList.length;
    },
    handleSourceData(data) {
      let col = data.filter((v) => {
        if (v.function && v.function == "query") {
          this.queryArr.push(v);
        } else {
          if (v.children) {
            v.children = this.handleSourceData(v.children);
          }
          return v;
        }
      });
      return col;
    },
    getResource() {
      // 获取信息
      this.$axios.get("/acb/2.0/bossManagerRole/getPortal").then((res) => {
        const list = [];
        const arr = [];
        const checkboxArr = [];
        const result = res.value;
        for (var k in result) {
          list.push(k);
        }
        console.log("result-->", result);
        if (result.CHARGING_BUSINESS) {
          list.forEach((index, item) => {
            const i = index;
            // result[i].forEach((element) => {
            //   if (element.children) {
            //     delete element.children;
            //   }
            // });
            if (index == "PARKING_BUSINESS") {
              index = "路内停车管理系统";
              arr[0] = {
                desc: index,
                code: i,
                children: result[i],
              };
            } else if (index == "PARKING_SYSTEM") {
              index = "路外停车管理系统";
              arr[1] = {
                desc: index,
                code: i,
                children: result[i],
              };
            } else if (index == "CHARGING_BUSINESS") {
              index = "新能源充电系统";
              arr[2] = {
                desc: index,
                code: i,
                children: result[i],
              };
            } else if (index == "PARKING_GUIDANCE") {
              index = "停车诱导";
              arr[3] = {
                desc: index,
                code: i,
                children: result[i],
              };
            } else if (index == "DATA_ANALYSIS") {
              index = "数据分析与预测";
              arr[4] = {
                desc: index,
                code: i,
                children: result[i],
              };
            } else if (index == "TENANT_MANAGE") {
              index = "商户管理系统";
              arr[5] = {
                desc: index,
                code: i,
                children: result[i],
              };
            } else if (index == "PRODUCT_OPERATION") {
              index = "运营中心";
              arr[6] = {
                desc: index,
                code: i,
                children: result[i],
              };
            } else if (index == "FINANCE_CENTER") {
              index = "财务管理";
              arr[7] = {
                desc: index,
                code: i,
                children: result[i],
              };
            } else if (index == "SYSTEM_MANAGEMENT") {
              index = "系统管理";
              arr[8] = {
                desc: index,
                code: i,
                children: result[i],
              };
            } else if (index == "PARKING_FILING") {
              index = "车场备案";
              arr[9] = {
                desc: index,
                code: i,
                children: result[i],
              };
            }
            checkboxArr.push(result[i]);
          });
        } else {
          list.forEach((index, item) => {
            const i = index;
            // result[i].forEach((element) => {
            //   if (element.children) {
            //     delete element.children;
            //   }
            // });
            if (index == "PARKING_BUSINESS") {
              index = "路内停车管理系统";
              arr[0] = {
                desc: index,
                code: i,
                children: result[i],
              };
            } else if (index == "PARKING_SYSTEM") {
              index = "路外停车管理系统";
              arr[1] = {
                desc: index,
                code: i,
                children: result[i],
              };
            } else if (index == "PARKING_GUIDANCE") {
              index = "停车诱导";
              arr[2] = {
                desc: index,
                code: i,
                children: result[i],
              };
            } else if (index == "DATA_ANALYSIS") {
              index = "数据分析与预测";
              arr[3] = {
                desc: index,
                code: i,
                children: result[i],
              };
            } else if (index == "TENANT_MANAGE") {
              index = "商户管理系统";
              arr[4] = {
                desc: index,
                code: i,
                children: result[i],
              };
            } else if (index == "PRODUCT_OPERATION") {
              index = "运营中心";
              arr[5] = {
                desc: index,
                code: i,
                children: result[i],
              };
            } else if (index == "FINANCE_CENTER") {
              index = "财务管理";
              arr[6] = {
                desc: index,
                code: i,
                children: result[i],
              };
            } else if (index == "SYSTEM_MANAGEMENT") {
              index = "系统管理";
              arr[7] = {
                desc: index,
                code: i,
                children: result[i],
              };
            } else if (index == "PARKING_FILING") {
              index = "车场备案";
              arr[8] = {
                desc: index,
                code: i,
                children: result[i],
              };
            }
            checkboxArr.push(result[i]);
          });
        }
        this.sourceData = arr;
        // this.setIsDeabled(this.isBIANJI);
        this.setIsDeabled(!this.isBIANJI);
        this.getAllLength(checkboxArr);
      });
    },
    getCheckedKeys() {
      let treeNodes = this.$refs.tree.getCheckedNodes();
    },
    save() {
      // 获取已选择的权限
      let allId = this.$refs.tree.getCheckedNodes(true);
      const elecResourceIdsList = [];
      const resourceIdsList = [];
      allId.forEach((item, index) => {
        // item.serverUri == 'acc' ? (elecResourceIdsList.push(item.code)) : (resourceIdsList.push(item.code));
        if (item.serverUri == "acc") {
          elecResourceIdsList.push(item.code);
        } else {
          // resourceIdsList.push(item.code);
        }
        resourceIdsList.push(item.code);
      });
      this.formInline.resourceIds = resourceIdsList.join(",");
      this.formInline.elecResourceIds = elecResourceIdsList.join(",");

      // this.formInline.resourceIds = this.$refs.tree
      //   .getCheckedKeys(true)
      //   .join(",");
      if (this.formInline.resourceIds == "") {
        this.$alert("必须选择权限", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      let url = "/acb/2.0/deptManagerResource/save";
      this.$axios
        .post(url, {
          data: {
            deptManagerResourceId: this.deptManagerResourceId,
            deptId: this.deptId,
            resourceIds: this.formInline.resourceIds,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.$router.go(-1);
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.isBIANJI = false;
          }
        });
    },
    getRoleDetail() {
      this.$axios
        .post("/acb/2.0/deptManagerResource/detail", {
          data: {
            deptId: this.deptId,
          },
        })
        .then((res) => {
          let data = res.value;
          this.deptManagerResourceId = data.deptManagerResourceId;
          // const arrid = data.resourceIds
          //   .split(",")
          //   .concat(data.elecResourceIds.split(","));
          const arrid = data.resourceIds.split(",");
          this.$refs.tree.setCheckedKeys(arrid);
          setTimeout(() => {
            this.checkAll = this.checkArr.length === arrid.length;
          }, 300);
        });
    },
    getAllLength(arr) {
      // arr [[{},{}],[{},{}]]
      arr.forEach((item, i) => {
        item.forEach((childItem, j) => {
          if (childItem.children) {
            this.findChild(childItem.children);
          } else {
            this.checkArr.push(childItem.code);
          }
        });
      });
    },
    findChild(list) {
      list.forEach((item, i) => {
        if (item.children) {
          this.findChild(item.children);
        } else if (item.type == "2" || !item.children) {
          this.checkArr.push(item.code);
        }
      });
    },
    changeUp() {
      this.dialogVisible = true;
    },
    // 平台logo上传
    handleAvatarSuccess(res, file, fileList, type) {
      console.log(res);
      if (type == 1) {
        this.loginLogoUrl = res.value;
      } else if (type == 2) {
        this.loginBackgroundUrl = res.value;
      } else if (type == 3) {
        this.logoUrl = res.value;
      } else if (type == 4) {
        this.backgroundUrl = res.value;
      }
    },
    beforeAvatarUpload(file, id) {
      this.backId = id;
      const isJPG = file.type === "image/jpeg" || "image/png";
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG或PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 5MB!");
      }
      return isJPG && isLt2M;
    },
    handlePictureCardPreview(file) {
      console.log(file.url);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    submitSet() {},
  },

  components: {
    // TitleModule
  },
  created() {
    this.getResource();
    this.getRoleDetail();
  },
  mounted() {
    this.isBIANJI = false;
    setTimeout(() => {
      this.$refs.tree.filter();
    }, 300);
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- <style scoped lang="stylus" rel="stylesheet/stylus">-->
<style scoped>
.breadcrumb {
  height: 35px;
}
.content {
  background: #ffffff;
  overflow: hidden;
  border-radius: 4px;
  padding: 20px;
}
.el-tree {
  padding-left: 20px;
  color: #1f2d3d;
}
>>> .el-tree > .el-tree-node > .el-tree-node__content:first-child {
  padding: 4px 0;
  margin-bottom: 10px;
  background-color: #f4f7fa;
}
/* >>>.el-icon-caret-right:before{
  content: "";
} */
.all {
  line-height: 50px;
  background: #f4f7fa;
  padding-left: 20px;
  margin-bottom: 20px;
}
.titleLeft {
  color: #1f2d3d;
  font-size: 16px;
  font-weight: 600;
  margin-right: 28px;
}
.roleName {
  margin-bottom: 20px;
  color: gray;
}
.setUp_edit {
  position: absolute;
  right: 20px;
  color: blue;
}

.el-col-offset-6 {
  margin: 0;
}

.el-col-6 {
  float: none;
}
.iconBut i {
  margin-left: 10px;
}

.avatar-small {
  width: 120px;
  height: 160px;
  display: block;
}
.avatar-uploader /deep/ .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader /deep/ .el-upload:hover {
  border-color: #0f6eff;
}

.avatar-uploader-icon-small {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 160px;
  line-height: 160px;
  text-align: center;
}
.area-tree-box {
  max-height: 45vh;
}
.title-mess {
  height: 24px;
  font-size: 16px;
  font-family: "PingFang";
  font-weight: 500;
  color: #1d2129;
  line-height: 24px;
  margin: 0 0 20px -20px;
}
.img_illustrate {
  height: 22px;
  font-size: 12px;
  font-family: "PingFang";
  font-weight: 400;
  color: #86909c;
  line-height: 22px;
  padding: 0 40px 0 100px;
}
.button_margin {
  margin: 100px 0 0 22px;
}
</style>
