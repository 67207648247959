var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-tree", {
        ref: "tree",
        attrs: {
          data: _vm.data,
          "node-key": _vm.key1,
          props: _vm.defaultProps,
          load: _vm.loadNode1,
          "check-strictly": true,
          lazy: "",
          "show-checkbox": "",
        },
        on: {
          "check-change": _vm.checkChange,
          "node-click": _vm.handleNodeClick,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }