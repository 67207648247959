<template>
  <div>
    <el-tree
      ref="tree"
      :data="data"
      :node-key="key1"
      @check-change="checkChange"
      :props="defaultProps"
      :load="loadNode1"
      :check-strictly="true"
      lazy
      @node-click="handleNodeClick"
      show-checkbox
    ></el-tree>
  </div>
</template>
<script>
export default {
  data() {
    return {
      curChecked: [],
      typeChangeFlag: false,
      dialogVisible: false,
      treeType: "1",
      data: [
        {
          label: "一级 1",
          children: [
            {
              label: "二级 1-1",
              children: [
                {
                  label: "三级 1-1-1"
                }
              ]
            }
          ]
        },
        {
          label: "一级 2",
          children: [
            {
              label: "二级 2-1",
              children: [
                {
                  label: "三级 2-1-1"
                }
              ]
            },
            {
              label: "二级 2-2",
              children: [
                {
                  label: "三级 2-2-1"
                }
              ]
            }
          ]
        },
        {
          label: "一级 3",
          children: [
            {
              label: "二级 3-1",
              children: [
                {
                  label: "三级 3-1-1"
                }
              ]
            },
            {
              label: "二级 3-2",
              children: [
                {
                  label: "三级 3-2-1"
                }
              ]
            }
          ]
        }
      ]
    };
  },
  watch: {
    disabled(newVal) {
      // this.disabled = newVal;
      // this.data.forEach((v) => {
      //   v.childrenAreas.forEach(el => {
      //     console.log(el, 'kdkaksd')
      //     el.disabled = newVal;
      //     console.log('jinlema')
      //   })
      // })
      if (this.treeType == 2) {
        this.setIsDeabled(this.data, newVal)
      } else if (this.treeType == 1) {
        this.setIsleaf(this.data)
      }
    }
  },
  props: {
    treeData: {
      default: function() {
        return [];
      }
    },
    defaultProps: {
      default: function() {
        return {
          label: "areaName",
          children: "childrenAreas",
          isLeaf: "leaf"
        };
      }
    },
    key1: {
      default: "areaId"
    },
    selectParentList: {
      default: function() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },
  methods: {
    checkChange() {
      this.curChecked = this.$refs.tree.getCheckedKeys();
    },
    setKeys(arr) {
      this.curChecked = arr;
      this.$refs.tree.setCheckedKeys(arr);
    },
    setChangeFlag() {
      this.typeChangeFlag = true;
    },
    setTreeType(type) {
      this.treeType = type;
      this.getArea();
      this.setKeys([]);
    },
    // 管辖方式按区级进入  setTreeType为1
    setIsleaf(data) {
      // console.log(data)
      data.forEach(v => {
        if (v.childrenAreas && v.childrenAreas.length) {
          // 为第一级
          v.disabled = true;
          this.setIsleaf(v.childrenAreas);
        } else {
          // 为第二级
          v.leaf = true;  // 为true时管辖方式为区级，管辖区域到市
          if (this.disabled) {
            v.disabled = true;
          } else {
            v.disabled = false;
          }
        }
      });
      return data;
    },
    // 管辖方式按街道管理进入 setTreeType为2
    setIsDeabled(data, newVal) {
      data.forEach(v => {
        v.disabled = true;
        if (v.childrenAreas && v.childrenAreas.length) {
          this.setIsDeabled(v.childrenAreas);
          if (newVal) {
            v.childrenAreas.forEach(ee => {
              if (ee.children) {
                  ee.children.forEach(el => {
                  el.disabled = true;
               })
              }
            })
          } else {
            v.childrenAreas.forEach(ee => {
              if (ee.children) {
                  ee.children.forEach(el => {
                  el.disabled = false;
               })
              }
            })
          }
        }
      });
      return data;
    },
    loadNode1(node, resolve) {
      if (node.data.childrenAreas && node.data.childrenAreas.length) {
        resolve(node.data.childrenAreas);
        if (this.typeChangeFlag) return;
        this.setKeys([
          ...this.filterNode(this.curChecked, node.data.areaId),
          ...this.selectParentList[node.data.areaId]
        ]);
        return;
      }
      if (node.level == 0) {
        this.getArea(node, resolve);
      } else if (this.treeType == 2 && node.data.depth <= 3) {
        node.checked = false;
        node.data.disabled = true;
        this.getChildren(node, resolve);
      } else {
        resolve([]);
      }
    },
    getArea(node, resolve) {
      let url = "/acb/2.0/systems/loginUser/getAllArea";
      this.$axios.get(url).then(res => {
        if (res.state == 0) {
          // if (this.treeType == 1) {
          //    this.setIsleaf(res.value);
          //   } else {
          //     this.setIsDeabled(res.value);
          //   }
          if (resolve) {
            if (this.treeType == 1) {
              resolve(this.setIsleaf(res.value));
              this.data = res.value
            } else {
              resolve(this.setIsDeabled(res.value[0]));
              this.data = res.value[0]
            }
          } else {
            if (this.treeType == 1) {
              this.data = this.setIsleaf(res.value);
            } else {
              this.data = this.setIsDeabled(res.value);
            }
          }
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      });
    },
    // 过滤掉被选中的节点
    filterNode(arr, aim) {
      return arr.filter(v => {
        return v != aim;
      });
    },
    // 调用市下面的区级
    getChildren(node, resolve) {
      let url = "/acb/2.0/systems/loginUser/getChildrenArea";
      this.$axios
        .get(url, {
          data: {
            areaId: node.data.areaId
          }
        })
        .then(res => {
          if (res.state == 0) {
           this.data[0].childrenAreas.forEach((v) => {
             res.value.forEach((el) => {
               if (v.areaId == el.parentId) {
                 v.children = res.value
             }
             })
           })
            resolve(this.setIsleaf(res.value));
            if (Object.keys(this.selectParentList).length) {
              this.setKeys([
                ...this.filterNode(this.curChecked, node.data.areaId),
                ...this.selectParentList[node.data.areaId]
              ]);
            }
            // this.treeData = this.sortArea(res.value);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        });
    },
    open() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    handleNodeClick() {},
    sureButton() {
      let arr = [];
      if (this.typeChangeFlag) {
        return this.$refs.tree.getCheckedKeys();
      }
      this.$refs.tree.getCheckedKeys().forEach(val => {
        if (this.selectParentList[val]) {
          this.selectParentList[val].forEach(C => {
            arr.push(C);
          });
        } else {
          arr.push(val);
        }
      });
      return arr;
    }
  },
  created() {
    //   this.getArea()
  },
  mounted() {}
};
</script>
<style scoped>
.dialog-footer {
  text-align: center;
}
</style>
