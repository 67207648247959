var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page1 treeWraper organizationManageWraper" },
    [
      _c("div", { staticClass: "content graphShadow" }, [
        _c(
          "div",
          { staticClass: "tree-box" },
          [
            _c("el-tree", {
              ref: "tree",
              attrs: {
                data: _vm.treeData,
                props: _vm.defaultProps,
                "node-key": "deptId",
                "expand-on-click-node": false,
              },
              on: { "node-click": _vm.nodeClick },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ node, data }) {
                    return _c("span", { staticClass: "custom-tree-node" }, [
                      _c("span", [_vm._v(_vm._s(node.label))]),
                      _c("span", { staticClass: "iconBut" }, [
                        _vm.$route.meta.authority.button.add == 1
                          ? _c("i", {
                              staticClass: "el-icon-circle-plus",
                              on: { click: () => _vm.append(data) },
                            })
                          : _vm._e(),
                        _vm.$route.meta.authority.button.update == 1
                          ? _c("i", {
                              staticClass: "el-icon-edit",
                              on: { click: () => _vm.edit(data) },
                            })
                          : _vm._e(),
                        node.level != 1 &&
                        _vm.$route.meta.authority.button.delete == 1
                          ? _c("i", {
                              staticClass: "el-icon-remove",
                              on: { click: () => _vm.remove(data) },
                            })
                          : _vm._e(),
                      ]),
                    ])
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm.selectNodeConfig.level == 1 || _vm.selectNodeConfig.level == 2
          ? _c("div", { staticClass: "setting-slide" }, [
              _c(
                "div",
                { staticClass: "setting-head" },
                [
                  _vm.tips == 1
                    ? _c(
                        "el-tabs",
                        {
                          model: {
                            value: _vm.activeName,
                            callback: function ($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName",
                          },
                        },
                        [
                          _c("el-tab-pane", {
                            attrs: { label: "基础配置", name: "base" },
                          }),
                          _vm.selectNodeConfig.level == 2
                            ? _c("el-tab-pane", {
                                attrs: { label: "功能配置", name: "function" },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "setting-main" }, [
                _vm.activeName == "base" && _vm.tips == 1
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-form",
                          {
                            ref: "shortForm",
                            attrs: {
                              model: _vm.shortForm,
                              "label-position": "right",
                              "label-width": "110px",
                              rules: _vm.rule,
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "box-padding" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "complainName",
                                      label: "机构名称",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.selectNodeConfig.deptName)
                                    ),
                                    _vm.isBIANJI &&
                                    _vm.selectNodeConfig.deptName == "AIpark"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "initOne",
                                            attrs: { disabled: !_vm.isBIANJI },
                                            on: { click: _vm.setInit },
                                          },
                                          [_vm._v("恢复默认")]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm.selectNodeConfig.level == 2
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "管辖方式",
                                          prop: "complainName",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "240px" },
                                            attrs: { disabled: !_vm.isBIANJI },
                                            on: { change: _vm.typeChange },
                                            model: {
                                              value: _vm.shortForm.manageType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.shortForm,
                                                  "manageType",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "shortForm.manageType",
                                            },
                                          },
                                          _vm._l(_vm.manageList, function (v) {
                                            return _c("el-option", {
                                              key: v.code,
                                              attrs: {
                                                label: v.desc,
                                                value: v.code,
                                              },
                                            })
                                          }),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-form-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.selectNodeConfig.level == 2,
                                        expression:
                                          "selectNodeConfig.level == 2",
                                      },
                                    ],
                                    attrs: {
                                      label: "管辖区域",
                                      prop: "complainName",
                                    },
                                  },
                                  [
                                    _c("areaTree", {
                                      ref: "areaTreeCon",
                                      staticClass: "area-tree-box",
                                      staticStyle: { width: "240px" },
                                      attrs: {
                                        selectParentList: _vm.selectParentList,
                                        disabled: !_vm.isBIANJI,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.selectNodeConfig.deptId == "1"
                              ? _c(
                                  "div",
                                  { staticClass: "box-padding" },
                                  [
                                    _c("div", { staticClass: "title-mess" }, [
                                      _vm._v("登录页"),
                                    ]),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "封面标题:" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: !_vm.isBIANJI },
                                          model: {
                                            value: _vm.shortForm.loginTitle,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.shortForm,
                                                "loginTitle",
                                                $$v
                                              )
                                            },
                                            expression: "shortForm.loginTitle",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "封面LOGO:",
                                          prop: "loginLogoId",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "avatar-uploader",
                                            attrs: {
                                              disabled: !_vm.isBIANJI,
                                              headers: _vm.uploadHeader,
                                              action:
                                                "/acb/2.0/deptProject/uploadLogo",
                                              name: "image",
                                              data: {
                                                deptId:
                                                  _vm.shortForm.loginLogoId,
                                              },
                                              "show-file-list": false,
                                              "on-success": (
                                                response,
                                                file,
                                                fileList
                                              ) =>
                                                _vm.handleAvatarSuccess(
                                                  response,
                                                  file,
                                                  fileList,
                                                  1
                                                ),
                                              "before-upload": (file) =>
                                                _vm.beforeAvatarUpload(
                                                  file,
                                                  "loginLogoId"
                                                ),
                                            },
                                          },
                                          [
                                            _vm.loginLogoUrl
                                              ? _c("img", {
                                                  staticClass: "avatar-small",
                                                  attrs: {
                                                    src: _vm.loginLogoUrl,
                                                  },
                                                })
                                              : _c("i", {
                                                  staticClass:
                                                    "el-icon-plus avatar-uploader-icon-small",
                                                }),
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          { staticClass: "img_illustrate" },
                                          [
                                            _vm._v(
                                              " 只能上传(jpg,jpeg,bmp,png)格式，尺寸48*48且大小不超过5M. "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "封面背景:",
                                          prop: "loginBackgroundId",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "avatar-uploader",
                                            attrs: {
                                              disabled: !_vm.isBIANJI,
                                              headers: _vm.uploadHeader,
                                              action:
                                                "/acb/2.0/deptProject/uploadLogo",
                                              name: "image",
                                              data: {
                                                deptId:
                                                  _vm.shortForm
                                                    .loginBackgroundId,
                                              },
                                              "show-file-list": false,
                                              "on-success": (
                                                response,
                                                file,
                                                fileList
                                              ) =>
                                                _vm.handleAvatarSuccess(
                                                  response,
                                                  file,
                                                  fileList,
                                                  2
                                                ),
                                              "before-upload": (file) =>
                                                _vm.beforeAvatarUpload(
                                                  file,
                                                  "loginBackgroundId"
                                                ),
                                            },
                                          },
                                          [
                                            _vm.loginBackgroundUrl
                                              ? _c("img", {
                                                  staticClass: "avatar",
                                                  attrs: {
                                                    src: _vm.loginBackgroundUrl,
                                                  },
                                                })
                                              : _c("i", {
                                                  staticClass:
                                                    "el-icon-plus avatar-uploader-icon",
                                                }),
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          { staticClass: "img_illustrate" },
                                          [
                                            _vm._v(
                                              " 只能上传(jpg,jpeg,bmp,png)格式，尺寸1920*1080且大小不超过5M. "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "box-padding" },
                              [
                                _c("div", { staticClass: "title-mess" }, [
                                  _vm._v("首页"),
                                ]),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "导航标题:",
                                      prop: "platformName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: !_vm.isBIANJI },
                                      model: {
                                        value: _vm.shortForm.platformName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.shortForm,
                                            "platformName",
                                            $$v
                                          )
                                        },
                                        expression: "shortForm.platformName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "导航LOGO:",
                                      prop: "logoId",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-upload",
                                      {
                                        staticClass: "avatar-uploader",
                                        attrs: {
                                          disabled: !_vm.isBIANJI,
                                          headers: _vm.uploadHeader,
                                          action:
                                            "/acb/2.0/deptProject/uploadLogo",
                                          name: "image",
                                          data: {
                                            deptId: _vm.shortForm.logoId,
                                          },
                                          "show-file-list": false,
                                          "on-success": (
                                            response,
                                            file,
                                            fileList
                                          ) =>
                                            _vm.handleAvatarSuccess(
                                              response,
                                              file,
                                              fileList,
                                              3
                                            ),
                                          "before-upload": (file) =>
                                            _vm.beforeAvatarUpload(
                                              file,
                                              "logoId"
                                            ),
                                        },
                                      },
                                      [
                                        _vm.logoUrl
                                          ? _c("img", {
                                              staticClass: "avatar-small",
                                              attrs: { src: _vm.logoUrl },
                                            })
                                          : _c("i", {
                                              staticClass:
                                                "el-icon-plus avatar-uploader-icon-small",
                                            }),
                                      ]
                                    ),
                                    _c("p", { staticClass: "img_illustrate" }, [
                                      _vm._v(
                                        " 只能上传(jpg,jpeg,bmp,png)格式，尺寸48*48且大小不超过5M. "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "首页背景图:",
                                      prop: "backgroundId",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-upload",
                                      {
                                        staticClass: "avatar-uploader",
                                        attrs: {
                                          disabled: !_vm.isBIANJI,
                                          headers: _vm.uploadHeader,
                                          action:
                                            "/acb/2.0/deptProject/uploadLogo",
                                          name: "image",
                                          data: {
                                            deptId: _vm.shortForm.backgroundId,
                                          },
                                          "show-file-list": false,
                                          "on-success": (
                                            response,
                                            file,
                                            fileList
                                          ) =>
                                            _vm.handleAvatarSuccess(
                                              response,
                                              file,
                                              fileList,
                                              4
                                            ),
                                          "before-upload": (file) =>
                                            _vm.beforeAvatarUpload(
                                              file,
                                              "backgroundId"
                                            ),
                                        },
                                      },
                                      [
                                        _vm.backgroundUrl
                                          ? _c("img", {
                                              staticClass: "avatar",
                                              attrs: { src: _vm.backgroundUrl },
                                            })
                                          : _c("i", {
                                              staticClass:
                                                "el-icon-plus avatar-uploader-icon",
                                            }),
                                      ]
                                    ),
                                    _c("p", { staticClass: "img_illustrate" }, [
                                      _vm._v(
                                        " 只能上传(jpg,jpeg,bmp,png)格式，尺寸1920*1080且大小不超过5M. "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "底部技术说明:" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: !_vm.isBIANJI },
                                      model: {
                                        value: _vm.shortForm.footer,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.shortForm, "footer", $$v)
                                        },
                                        expression: "shortForm.footer",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm.isBIANJI
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "el_button",
                                    attrs: { type: "primary" },
                                    on: { click: _vm.submitJCPZ },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("button.preservation"))
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "el_button_off",
                                    on: {
                                      click: function ($event) {
                                        _vm.isBIANJI = false
                                      },
                                    },
                                  },
                                  [_vm._v("取消")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.isBIANJI
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "el_button",
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        _vm.isBIANJI = true
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("button.edit")))]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.activeName == "function" && _vm.tips == 1
                  ? _c(
                      "div",
                      [
                        _c("functionConfig", {
                          attrs: { deptId: _vm.selectNodeConfig.deptId },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogTableVisible,
            width: "40%",
            title: _vm.isEdit ? "修改部门" : "新建部门",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "msgboxCon",
              attrs: { model: _vm.formInline, rules: _vm.rules },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.labelNameValue,
                    "label-width": "120",
                    prop: "name",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "15" },
                    model: {
                      value: _vm.formInline.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShow,
                      expression: "isShow",
                    },
                  ],
                  attrs: {
                    label: _vm.labelCodeValue,
                    "label-width": "120",
                    prop: "code",
                  },
                },
                [
                  _c("el-cascader", {
                    attrs: {
                      options: _vm.cascaderData,
                      "expand-trigger": "click",
                      filterable: "",
                      "show-all-levels": false,
                      props: _vm.defaultProps,
                      "change-on-select": "",
                    },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.selectedOptions,
                      callback: function ($$v) {
                        _vm.selectedOptions = $$v
                      },
                      expression: "selectedOptions",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogTableVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.disabledBtn },
                  on: {
                    click: function ($event) {
                      return _vm.submit()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }