<template>
  <div class="page1 treeWraper organizationManageWraper">
    <!--主体内容-->
    <div class="content graphShadow">
      <div class="tree-box">
        <el-tree
          ref="tree"
          :data="treeData"
          :props="defaultProps"
          @node-click="nodeClick"
          node-key="deptId"
          :expand-on-click-node="false"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <span class="iconBut">
              <i
                class="el-icon-circle-plus"
                v-if="$route.meta.authority.button.add == 1"
                @click="() => append(data)"
              ></i>
              <i
                class="el-icon-edit"
                v-if="$route.meta.authority.button.update == 1"
                @click="() => edit(data)"
              ></i>
              <i
                class="el-icon-remove"
                v-if="node.level != 1 && $route.meta.authority.button.delete == 1"
                @click="() => remove(data)"
              ></i>
            </span>
          </span>
        </el-tree>
      </div>
      <div class="setting-slide" v-if="selectNodeConfig.level == 1 || selectNodeConfig.level == 2">
        <div class="setting-head">
          <el-tabs v-model="activeName" v-if="tips == 1">
            <el-tab-pane label="基础配置" name="base"> </el-tab-pane>
            <el-tab-pane label="功能配置" name="function" v-if="selectNodeConfig.level == 2">
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="setting-main">
          <div v-if="activeName == 'base' && tips == 1">
            <el-form
              ref="shortForm"
              :model="shortForm"
              label-position="right"
              label-width="110px"
              :rules="rule"
            >
              <div class="box-padding">
                <el-form-item prop="complainName" label="机构名称">
                  {{ selectNodeConfig.deptName
                  }}<span
                    class="initOne"
                    @click="setInit"
                    v-if="isBIANJI && selectNodeConfig.deptName == 'AIpark'"
                    :disabled="!isBIANJI"
                    >恢复默认</span
                  >
                </el-form-item>
                <el-form-item
                  label="管辖方式"
                  prop="complainName"
                  v-if="selectNodeConfig.level == 2"
                >
                  <el-select
                    v-model.trim="shortForm.manageType"
                    @change="typeChange"
                    style="width: 240px"
                    :disabled="!isBIANJI"
                  >
                    <el-option
                      :label="v.desc"
                      :value="v.code"
                      :key="v.code"
                      v-for="v in manageList"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="管辖区域"
                  prop="complainName"
                  v-show="selectNodeConfig.level == 2"
                >
                  <areaTree
                    style="width: 240px"
                    ref="areaTreeCon"
                    :selectParentList="selectParentList"
                    :disabled="!isBIANJI"
                    class="area-tree-box"
                  ></areaTree>
                </el-form-item>
              </div>
              <div class="box-padding" v-if="selectNodeConfig.deptId == '1'">
                <div class="title-mess">登录页</div>
                <el-form-item label="封面标题:">
                  <el-input v-model="shortForm.loginTitle" :disabled="!isBIANJI"></el-input>
                </el-form-item>
                <el-form-item label="封面LOGO:" prop="loginLogoId">
                  <el-upload
                    :disabled="!isBIANJI"
                    class="avatar-uploader"
                    :headers="uploadHeader"
                    action="/acb/2.0/deptProject/uploadLogo"
                    name="image"
                    :data="{ deptId: shortForm.loginLogoId }"
                    :show-file-list="false"
                    :on-success="
                      (response, file, fileList) => handleAvatarSuccess(response, file, fileList, 1)
                    "
                    :before-upload="(file) => beforeAvatarUpload(file, 'loginLogoId')"
                  >
                    <img v-if="loginLogoUrl" :src="loginLogoUrl" class="avatar-small" />
                    <i v-else class="el-icon-plus avatar-uploader-icon-small"></i>
                  </el-upload>
                  <p class="img_illustrate">
                    只能上传(jpg,jpeg,bmp,png)格式，尺寸48*48且大小不超过5M.
                  </p>
                  <!-- <canvas id="canvas"></canvas> -->
                </el-form-item>
                <el-form-item label="封面背景:" prop="loginBackgroundId">
                  <el-upload
                    :disabled="!isBIANJI"
                    class="avatar-uploader"
                    :headers="uploadHeader"
                    action="/acb/2.0/deptProject/uploadLogo"
                    name="image"
                    :data="{ deptId: shortForm.loginBackgroundId }"
                    :show-file-list="false"
                    :on-success="
                      (response, file, fileList) => handleAvatarSuccess(response, file, fileList, 2)
                    "
                    :before-upload="(file) => beforeAvatarUpload(file, 'loginBackgroundId')"
                  >
                    <img v-if="loginBackgroundUrl" :src="loginBackgroundUrl" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <p class="img_illustrate">
                    只能上传(jpg,jpeg,bmp,png)格式，尺寸1920*1080且大小不超过5M.
                  </p>
                  <!-- <canvas id="canvas"></canvas> -->
                </el-form-item>
              </div>
              <div class="box-padding">
                <div class="title-mess">首页</div>
                <el-form-item label="导航标题:" prop="platformName">
                  <el-input v-model="shortForm.platformName" :disabled="!isBIANJI"></el-input>
                </el-form-item>
                <el-form-item label="导航LOGO:" prop="logoId">
                  <el-upload
                    :disabled="!isBIANJI"
                    class="avatar-uploader"
                    :headers="uploadHeader"
                    action="/acb/2.0/deptProject/uploadLogo"
                    name="image"
                    :data="{ deptId: shortForm.logoId }"
                    :show-file-list="false"
                    :on-success="
                      (response, file, fileList) => handleAvatarSuccess(response, file, fileList, 3)
                    "
                    :before-upload="(file) => beforeAvatarUpload(file, 'logoId')"
                  >
                    <img v-if="logoUrl" :src="logoUrl" class="avatar-small" />
                    <i v-else class="el-icon-plus avatar-uploader-icon-small"></i>
                  </el-upload>
                  <p class="img_illustrate">
                    只能上传(jpg,jpeg,bmp,png)格式，尺寸48*48且大小不超过5M.
                  </p>
                </el-form-item>
                <el-form-item label="首页背景图:" prop="backgroundId">
                  <el-upload
                    :disabled="!isBIANJI"
                    class="avatar-uploader"
                    :headers="uploadHeader"
                    action="/acb/2.0/deptProject/uploadLogo"
                    name="image"
                    :data="{ deptId: shortForm.backgroundId }"
                    :show-file-list="false"
                    :on-success="
                      (response, file, fileList) => handleAvatarSuccess(response, file, fileList, 4)
                    "
                    :before-upload="(file) => beforeAvatarUpload(file, 'backgroundId')"
                  >
                    <img v-if="backgroundUrl" :src="backgroundUrl" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <p class="img_illustrate">
                    只能上传(jpg,jpeg,bmp,png)格式，尺寸1920*1080且大小不超过5M.
                  </p>
                </el-form-item>
                <el-form-item label="底部技术说明:">
                  <el-input v-model="shortForm.footer" :disabled="!isBIANJI"></el-input>
                </el-form-item>
              </div>
            </el-form>
            <div v-if="isBIANJI">
              <el-button class="el_button" type="primary" @click="submitJCPZ">{{ $t('button.preservation') }}</el-button>
              <el-button class="el_button_off" @click="isBIANJI = false">取消</el-button>
            </div>
            <div v-if="!isBIANJI">
              <el-button class="el_button" type="primary" @click="isBIANJI = true">{{ $t('button.edit') }}</el-button>
            </div>
          </div>
          <div v-if="activeName == 'function' && tips == 1">
            <functionConfig :deptId="selectNodeConfig.deptId"></functionConfig>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogTableVisible"
      width="40%"
      :title="isEdit ? '修改部门' : '新建部门'"
    >
      <el-form :model="formInline" ref="ruleForm" class="msgboxCon" :rules="rules">
        <el-form-item :label="labelNameValue" label-width="120" prop="name">
          <el-input v-model.trim="formInline.name" maxlength="15"></el-input>
        </el-form-item>
        <el-form-item :label="labelCodeValue" label-width="120" prop="code" v-show="isShow">
          <el-cascader
            :options="cascaderData"
            expand-trigger="click"
            filterable
            :show-all-levels="false"
            v-model="selectedOptions"
            :props="defaultProps"
            change-on-select
            @change="handleChange"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" :disabled="disabledBtn" @click="submit()">确 定</el-button>
      </div>
    </el-dialog>
    <!-- <el-button @click="downloadMore"></el-button> -->
  </div>
</template>
<script>
import areaTree from "./components/areaTree";
import functionConfig from "./components/functionConfig";
import { saveAs } from "file-saver";
import JSZip from "jszip";
export default {
  name: "deptManage",
  data() {
    return {
      tips: 1,
      disabledBtn: false,
      isChange: false,
      isShow: true,
      selectedId: "",
      currentData: {},
      isEdit: false,
      selectedOptions: [],
      treeData: [],
      cascaderData: [],
      backId: "",
      labelNameValue: "部门名称：",
      labelCodeValue: "上级部门：",
      formInline: {
        name: "",
        code: "",
      },
      dialogTableVisible: false,
      areaList: [],
      childrenArea: [],
      defaultProps: {
        children: "childNodes",
        label: "deptName",
        value: "deptId",
      },
      ruleForm: {
        areaName: "",
        order: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入机构名称",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value) {
                this.formInline.name = value.replace(/\.+/g, "");
                callback();
              }
            },
            trigger: "change",
          },
        ],
        code: [
          {
            required: true,
            message: "请选择上级部门",
            trigger: "blur",
          },
        ],
      },
      rule: {
        logoId: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        backgroundId: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        footerName: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        loginTitle: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        loginLogoId: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        loginBackgroundId: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        complainName: [
          {
            required: true,
            trigger: "blur",
          },
        ],
      },
      activeName: "base",
      logoUrl: "", // 平台logo
      loginLogoUrl: "",
      loginBackgroundUrl: "",
      backgroundUrl: "",
      shortForm: {
        deptProjectId: "",
        platformName: "", // 导航标题
        loginTitle: "", // 登录页标题
        deptId: "",
        interfaceStyle: 1,
        manageType: "", // 管辖方式
        areaLists: "",
        footer: "", // 底部技术说明
      },
      manageList: [],
      selectParentList: [], // tree 树  默认选中数据
      selectNodeConfig: {}, // 点击一级二级组织机构节点配置
      isClickNodeText: true, // 是否点击在文字上
      uploadHeader: {
        "Session-Id": sessionStorage.token,
      },
      isBIANJI: false,
      watchdeptId: "",
      imgUrl: require("./img/bg.png"),
      fileUrl: null,
    };
  },
  methods: {
    setImage() {
      var image = new Image();
      var canvas = document.createElement("canvas");
      // 解决图片跨域，不然会报错
      image.src = this.imgUrl;
      let that = this;
      image.onload = function () {
        canvas.width = image.width;
        canvas.height = image.height;
        // 获取创建好的canvas
        var ctx = canvas.getContext("2d");
        // 将图片绘制到canvas上
        ctx.drawImage(image, 0, 0);
        // 将画布转化为base64的数据
        var dataURL = canvas.toDataURL("image/png");

        // 将获取到的base64数据转为二进制数组
        var arr = dataURL.split(","),
          bstr = atob(arr[1]),
          // 图片后缀
          mime = arr[0].match(/:(.*?);/)[1],
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        var blob = new Blob([u8arr], { type: mime });
        // blob转file
        var file = new File([blob], "", { type: "image/png", lastModified: Date.now() });
        // blob的地址
        var url = URL.createObjectURL(blob);
        that.logoUrl = url;
        that.fileUrl = file;
      };
    },
    setInit() {
      // this.uploadPicture();
      this.shortForm.platformName = "智慧泊车管理平台";
      this.shortForm.footer = "智慧互通科技有限公司";
      this.getDefaultDeptProjectLogo();
    },
    getDefaultDeptProjectLogo() {
      // /acb/2.0/dept/getDeptTree
      this.$axios.get("/acb/2.0/deptProject/defaultDeptProjectLogo").then((res) => {
        this.logoUrl = res.value;
        this.setImage();
        console.log("恢复默认", this.logoUrl);
      });
    },
    // 上传图片
    uploadPicture(img) {
      this.$refs;
      return new Promise((resolve, reject) => {
        const image = new FormData();
        image.append("image", img);
        image.append("deptId", this.selectNodeConfig.deptId);
        const opt = {
          url: "/acb/2.0/deptProject/uploadLogo",
          method: "post",
          data: image,
          config: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
          success: (res) => {
            resolve();
          },
        };
        // console.log("opt", opt);
        this.$request(opt);
      });
    },
    resetShortForm() {
      this.logoUrl = ""; // 平台logo
      this.shortForm = {
        deptProjectId: "",
        platformName: "", // 平台名称
        footer: "",
        deptId: "",
        interfaceStyle: 1,
        manageType: "", // 管辖方式
        areaLists: "",
      };
    },
    nodeClick(data, node, store) {
      console.log("data", data);
      console.log("node", node);
      console.log("isClickNodeText", this.isClickNodeText);
      console.log("selectNodeConfig111", this.selectNodeConfig);
      this.activeData = data;
      this.activeNode = node;
      this.level = data.level;
      if (this.isClickNodeText) {
        if (data.level <= 2) {
          this.tips = 1;
          this.selectNodeConfig = data;
          console.log("selectNodeConfig", this.selectNodeConfig);
          this.resetShortForm();
          this.activeName = "base";
          this.getDeptProjectDetail(this.selectNodeConfig.deptId);
        } else {
          this.$alert("不允许配置", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          this.tips = 0;
        }
      } else {
        this.isClickNodeText = true;
      }
    },
    getTree() {
      // /acb/2.0/dept/getDeptTree
      this.$axios.get("/acb/2.0/dept/getByCurrentUser").then((res) => {
        this.treeData = [];
        this.treeData.push(res.value);
        this.treeData = [res.value];
        this.cascaderData = [res.value];
        this.selectNodeConfig = this.treeData[0];
        this.getDeptProjectDetail(this.selectNodeConfig.deptId);
      });
    },
    submit() {
      this.disabledBtn = true;
      setTimeout(() => {
        this.disabledBtn = false; // 点击一次时隔两秒后才能再次点击
      }, 2000);
      if (!this.isEdit) {
        this.$axios
          .post("/acb/2.0/dept/create", {
            data: {
              deptName: this.formInline.name,
              parentId: this.selectedId,
            },
          })
          .then((res) => {
            this.dialogTableVisible = false;
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            if (!this.isChange) {
              let name = this.formInline.name;
              let deptId = res.value;
              this.appendInfo(this.activeData, name, deptId);
            } else {
              this.getTree();
            }
          })
          .catch((res) => {});
      } else {
        this.$axios
          .post("/acb/2.0/dept/update", {
            data: {
              deptId: this.currentData.deptId,
              parentId: this.selectedId,
              deptName: this.formInline.name,
            },
          })
          .then((res) => {
            this.dialogTableVisible = false;
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            if (!this.isChange) {
              let name = this.formInline.name;
              this.$set(this.activeData, "deptName", name);
            } else {
              this.getTree();
            }
          })
          .catch((res) => {});
      }
    },
    appendInfo(data, name, deptId) {
      const newChild = {
        deptId: deptId,
        deptName: name,
        level: data.level * 1 + 1,
        parentId: data.deptId,
        childNodes: [],
      };
      if (data.childNodes && data.childNodes.length > 0) {
        this.$refs.tree.append(newChild, data.childNodes[0].parentId);
      } else {
        data.childNodes = [];
        this.$refs.tree.append(newChild, data.deptId);
      }
      this.cascaderData = JSON.parse(JSON.stringify(this.treeData));
    },
    disabledNode(list, nodeId) {
      let oneChange = true;
      for (let i = 0; i < list.length; i++) {
        let obj = list[i];
        obj.disabled = false;
        if (this.isEdit) {
          if (obj.deptId == nodeId && oneChange) {
            obj.disabled = true;
            oneChange = false;
          }
        }
        if (obj && obj.childNodes) {
          this.disabledNode(obj.childNodes, nodeId);
        }
      }
    },
    getNode(list, nodeId, lev) {
      for (let i = 0; i < list.length; i++) {
        let obj = list[i];
        if (obj.deptId == nodeId) {
          if (this.isEdit) {
            if (obj.level == lev) {
              break;
            }
            this.selectedOptions.unshift(obj.parentId);
          } else {
            this.selectedOptions.unshift(obj.deptId);
          }
          this.getNode(this.treeData, obj.parentId, lev);
          break;
        }
        if (obj && obj.childNodes) {
          this.getNode(obj.childNodes, nodeId, lev);
        }
      }
    },
    edit(data) {
      this.isClickNodeText = false;
      if (data.level == "1" && data.parentId == "0") {
      }
      this.formInline.name = data.deptName;
      this.currentData = data;
      this.isEdit = true;
      this.isChange = false;
      this.dialogTableVisible = true;
      this.selectedOptions = [];
      this.getNode(this.treeData, data.deptId, this.treeData[0].level);
      this.disabledNode(this.treeData, data.deptId);
      // this.selectedId = this.selectedOptions[this.selectedOptions.length - 1];
      if (data.level == this.treeData[0].level) {
        this.selectedId = this.treeData[0].parentId;
        this.isShow = false;
      } else {
        this.selectedId = this.selectedOptions[this.selectedOptions.length - 1];
        this.isShow = true;
      }
      this.cascaderData = JSON.parse(JSON.stringify(this.treeData));
    },
    append(data) {
      this.isClickNodeText = false;
      console.log(55555, "******");
      this.dialogTableVisible = true;
      this.isShow = true;
      this.isChange = false;
      this.formInline.name = "";
      this.currentData = data;
      this.isEdit = false;
      this.selectedOptions = [];
      // this.getNode(this.treeData, data.deptId);
      this.getNode(this.treeData, data.deptId, this.treeData[0].level);
      this.disabledNode(this.treeData, data.deptId);
      this.selectedId = this.selectedOptions[this.selectedOptions.length - 1];
    },
    deleteLocalDep(treeData, data) {
      this.$refs.tree.remove(this.activeData.deptId);
      this.cascaderData = JSON.parse(JSON.stringify(this.treeData));
    },
    deltree(data) {
      this.$axios.post(`/acb/2.0/dept/del/${this.currentData.deptId}`).then((_) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.deleteLocalDep(this.activeNode.parent, data);
      });
    },
    remove(data) {
      this.isClickNodeText = false;
      this.currentData = data;
      this.$confirm("是否确认删除该部门？", "删除部门", {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deltree(data);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleChange(value) {
      this.isChange = true;
      this.selectedId = value[value.length - 1];
    },
    // 获取下级区域列表
    getChildrenArea(areaId, resolve, node) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: areaId,
          },
        })
        .then((res) => {
          for (let i = 0; i < res.value.length; i++) {
            this.$set(res.value[i], "leaf", true);
          }
          resolve(res.value);
          this.$set(node.data, "children", res.value);
        });
    },
    // 获取当前用户的区域列表
    getArealist() {
      this.$axios.get("/acb/2.0/systems/loginUser/getAllArea").then((res) => {
        this.areaList = res.value;
      });
    },
    // 平台logo上传
    handleAvatarSuccess(res, file, fileList, type) {
      console.log(res);
      if (type == 1) {
        this.loginLogoUrl = res.value;
      } else if (type == 2) {
        this.loginBackgroundUrl = res.value;
      } else if (type == 3) {
        this.logoUrl = res.value;
      } else if (type == 4) {
        this.backgroundUrl = res.value;
      }
    },
    beforeAvatarUpload(file, id) {
      this.backId = id;
      const isJPG = file.type === "image/jpeg" || "image/png";
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG或PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 5MB!");
      }
      return isJPG && isLt2M;
    },
    // 获取管理方式
    getInfo() {
      this.$axios.get("/acb/2.0/bossManager/spinner").then((res) => {
        if (res.state == 0) {
          this.manageList = res.value.manageType;
          this.shortForm.manageType = res.value.manageType[0].code;
        }
      });
    },
    // 获取基础配置详情
    getDeptProjectDetail(deptId) {
      this.watchdeptId = deptId;
      this.$axios
        .post("/acb/2.0/deptProject/detail", {
          data: {
            deptId: deptId,
          },
        })
        .then((res) => {
          if (res.state == 0 && res.value) {
            let selectParentList = res.value.selectParentList;
            this.selectParentList = selectParentList;
            let arr = [];
            for (let i in selectParentList) {
              arr.push(i);
            }
            this.checkArr = arr;
            if (res.value.manageType == 1) {
              this.$refs.areaTreeCon.setTreeType(1);
            } else {
              this.$refs.areaTreeCon.setTreeType(2);
            }
            this.$refs.areaTreeCon.setKeys(arr);
            // this.manageList = res.value.manageType;
            // this.selectParentList = res.value.deptManagerAreaList;
            this.shortForm.platformName = res.value.platformName;
            this.shortForm.interfaceStyle = res.value.interfaceStyle;
            this.shortForm.manageType = res.value.manageType;
            this.shortForm.deptProjectId = res.value.deptProjectId;
            this.shortForm.logoId = res.value.logoId;
            this.shortForm.backgroundId = res.value.backgroundId;
            this.shortForm.loginLogoId = res.value.loginLogoId;
            this.shortForm.loginBackgroundId = res.value.loginBackgroundId;
            this.shortForm.loginTitle = res.value.loginTitle;
            this.shortForm.footer = res.value.footer;
            this.loginBackgroundUrl = res.value.loginBackgroundUrl;
            this.logoUrl = res.value.logoUrl;
            this.loginLogoUrl = res.value.loginLogoUrl;
            this.backgroundUrl = res.value.backgroundUrl;
          }
        });
    },
    // 多文件下载
    downloadMore() {
      const zip = new JSZip();
      const cache = {};
      const promises = [];
      let downArr = [];
      downArr.forEach((item) => {
        // item.downloadLink.split('?')[0] 处理companyAttachmentsList里的url地址 去除？号和后面的字符串
        const promise = this.download(item.downloadLink.split("?")[0]).then((data) => {
          // 下载文件, 并存成ArrayBuffer对象
          // item.fileName companyAttachmentsList里的文件名
          zip.file(item.fileName, data, { binary: true }); // 逐个添加文件
          cache[item.fileName] = data; // 可要可不要 用来打印查检查添加了那些文件
        });
        promises.push(promise); // 加到promises队列里
      });
      Promise.all(promises).then(() => {
        // 异步队列全部完成时 执行下面代码
        zip.generateAsync({ type: "blob" }).then((content) => {
          // 生成二进制流
          saveAs(content, "打包下载.zip"); // 利用file-saver保存文件
        });
      });
    },
    typeChange(item) {
      // item => 1,2
      this.$refs.areaTreeCon.setTreeType(item);
      this.$refs.areaTreeCon.setChangeFlag();
    },
    // 基础配置保存接口
    submitJCPZ() {
      if (this.selectNodeConfig.level == 2 && this.$refs.areaTreeCon.sureButton().length < 1) {
        this.$alert("请绑定区域！！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      if (this.selectNodeConfig.level == 2) {
        this.shortForm.areaLists = this.$refs.areaTreeCon.sureButton().join(",");
      }
      this.shortForm.deptId = this.selectNodeConfig.deptId;
      if (this.fileUrl) {
        this.uploadPicture(this.fileUrl);
      }
      this.$axios
        .post("/acb/2.0/deptProject/save", {
          data: this.shortForm,
        })
        .then((res) => {
          if (res.state == 0) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.isBIANJI = false;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  watch: {
    activeName(newVal, old) {
      if (newVal == "base") {
        this.getDeptProjectDetail(this.watchdeptId);
      }
    },
  },
  activated() {},
  created() {
    // this.getTree();
  },
  mounted() {
    // this.getArealist();
    this.getTree();
    this.getInfo();
  },
  components: {
    areaTree,
    functionConfig,
  },
};
</script>
<style media="screen">
.box-padding {
  padding: 20px 0 10px 32px;
  background: #fff;
  margin-bottom: 16px;
}
.setting-head {
  height: 36px;
  background: #f7f8fa;
}

.initOne {
  color: #fff;
  background-color: #66b1ff;
  border-radius: 4px;
  padding: 6px 18px;
  margin-left: 5px;
  cursor: pointer;
}
.organizationManageWraper .el-dialog {
  border-radius: 6px;
}
.organizationManageWraper .el-dialog__header {
  border-bottom: 1px solid #ddd;
}
.organizationManageWraper .el-dialog__title {
  font-size: 14px;
}
.treeButton {
  position: absolute;
  right: -100px;
  top: 0;
  color: #666;
}

.treeButton i {
  margin-right: 10px;
}

.treeButtonRelative {
  position: relative;
}
.treeWraper .el-form-item__content {
  float: left;
  /* width: 240px; */
}
.treeWraper .el-form-item__content input,
.treeWraper .el-cascader {
  width: 240px;
}
.msgboxCon label {
  text-align: right;
  float: left;
  font-size: 12px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.msgboxCon label::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}

.msgboxCon input {
  position: relative;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 60%;
}
.treeWraper .el-tree-node__content {
  height: 40px;
}
</style>
<style scoped lang="stylus" rel="stylesheet/stylus">
.iconBut {
  margin-left: 10px;

  i {
    margin-left: 10px;
  }
}

.breadcrumb {
  height: 35px;
}

.content {
  height:calc(100% - 56px)
  overflow-y: auto;
  background: #FFFFFF;
  overflow: hidden;
}

.tree-box, .setting-slide {
  display: inline-block;
  vertical-align: top;
}

.tree-box {
  width: 25%;
  height:calc(100% - 56px)
}

.setting-slide {
  width: 75%;
  border-left: 2px solid #E4E7ED;
  box-sizing: border-box;
}

.setting-main {
  width: 100%;
  overflow-y: auto;
  padding: 15px 15px 15px 20px;
  box-sizing: border-box;
  background: #F7F8FA
}

.avatar {
  width: 204px;
  height: 100px;
  display: block;
}
.avatar-small{
  width: 120px;
  height: 120px;
  display: block;
}

.avatar-uploader /deep/ .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader /deep/ .el-upload:hover {
  border-color: #0f6eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 204px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar-uploader-icon-small{
  font-size: 28px;
  color: #8c939d;
  width: 204px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.area-tree-box {
  max-height: 45vh;
}
.title-mess{
  height: 24px;
  font-size: 16px;
  font-family: "PingFang";
  font-weight: 500;
  color: #1D2129;
  line-height: 24px;
  margin:0 0 20px -20px;
}
.img_illustrate{
  height: 22px;
  font-size: 12px;
  font-family: "PingFang";
  font-weight: 400;
  color: #86909C;
  line-height: 22px;
}
/deep/ {
  .el-tabs__nav {
    margin-left: 4%;
  }

  .setting-main {
    .el-form-item__content {
      margin-left: 0 !important;
    }
  }
}
.el-form {
  .el-form-item {

    margin-bottom:16px;
    /deep/ * {
      font-size: 14px;
    }
    /deep/ .el-form-item__content {
      * { }
    }
    /deep/ .el-form-item__label {
      height:32px;
      line-height:40px;
    }
    /deep/ .el-form-item__content {
      input {
        height:32px;
        line-height:32px;
      }
    }
  }
}
</style>
